import React from 'react';
import NavBar from './NavBar';
import SideBar from './SideBar';

function Menu() {

  return (<React.Fragment>
             <NavBar />
             <SideBar />
          </React.Fragment>)

}

export default Menu;