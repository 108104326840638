import React, { useEffect, useState, useMemo } from 'react';

/**
 * props:
 * - symbol
 * - data
 */

function BookRow(props) {
     
    const [data, setData] = useState({
        bid: '0',
        ask: '0',
    })

    useEffect(() => {
        if (!props.data) return;

        if (data.bid !== props.data.bid)
            data.bid = props.data.bid;

        if (data.ask !== props.data.ask)
            data.ask = props.data.ask;  

            setData(data);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

    const bookRow = useMemo(() => (
        <tr>
            <td className="text-gray-900">{props.symbol}</td>
            <td className="text-gray-900">{`${data.bid}`.substring(0,8)}</td>
            <td className="text-gray-900">{`${data.ask}`.substring(0,8)}</td>
        </tr>
// eslint-disable-next-line react-hooks/exhaustive-deps
    ), [data.bid, data.ask])

    useEffect(() => {
        if (!props.data) return;

        if (data.bid !== props.data.bestBid)
           data.bid = props.data.bestBid;

        if (data.ask !== props.data.bestAsk)
           data.ask = props.data.bestAsk;
    
        setData(data);
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);


    return (bookRow);
}

export default BookRow;