import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { searchSymbols, syncSymbols } from '../../services/SymbolsService';
import SymbolRow from './SymbolRow';
import SelectQuote, { getDefaultQuote, setDefaultQuote } from '../../components/SelectQuote/SelectQuote';
import SymbolModal from './SymbolModal';
import Pagination from '../../components/Pagination/Pagination';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';

function Symbols() {

    const history = useHistory();

    const defaultLocation = useLocation();
    

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page') || 1;
    }

    useEffect(() =>{
        return history.listen(location => {
            setPage(getPage(location));
        })

    }, [history])

    const [symbols, setSymbols] = useState([]);
    
    const [page, setPage] = useState(getPage());
    const [notification, setNotification] = useState({ type: '', text: '' });
    const [count, setCount] = useState(0);

    const [isSyncing, setIsSyncing] = useState(false);
    const [quote, setQuote] = useState(getDefaultQuote());
    const [viewSymbol, setViewSymbol]= useState({
        basePrecision: 0,
        quotePrecision: 0,
        symbol: '',
        MinNotional: '',
        minLotSize: ''
        
    })    

function errorHandLing(err) {
    console.error(err.response ? err.response.data : err.message);
    setNotification({type: 'error', text: err.response ? err.response.data : err.message});
     
}

function onSyncClick(event) {
    const token = localStorage.getItem("token");
    setIsSyncing(true);
    syncSymbols(token)
        .then(response => {
            setNotification({ type: 'success', text: 'Synced successfully!!' });
            setIsSyncing(false)
        })
        .catch(err => {
        // if (err.response && err.response.status === 401) return history.push('/');
            errorHandLing(err)
            setIsSyncing(false);
    })
 }

 function onQuoteChange(event) {
    setQuote(event.target.value);
    setDefaultQuote(event.target.value);
}

function loadSymbols(selectedValue) {
      const token = localStorage.getItem('token');
      const search = selectedValue === 'FAVORITES' ? '' : selectedValue;
      const onlyFavorites = selectedValue === 'FAVORITES';
       searchSymbols(search, onlyFavorites, getPage(), token)
        .then(result => {
           setSymbols(result.rows);
           setCount(result.count);
        })
        .catch(err => errorHandLing(err))
   }
    
    useEffect(() => {
          loadSymbols(quote);
// eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isSyncing, quote, page])


function onViewClick(event) {
    const coinpair = event.target.id.replace("view", "");
    const symbol = symbols.find(s => s.symbol === coinpair);
    setViewSymbol(symbol);
}

    return (
       <React.Fragment>
        <Menu />
        <main className="content">
            <div className="row py-4">
                <div className="col-8">
                    <h2 className="h4">Symbols</h2>
                </div>
                  <div className="col-2">
                  <SelectQuote onChange={onQuoteChange} />
                </div>
                <div className="col-2">
                <button className="btn btn-primary animate-up-2" type="button" onClick={onSyncClick}>
                        <svg className="icon icon-xs" fill="white" stroke="currentColor" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" /></svg>
                                    {isSyncing ? "Syncing..." : "Sync"}
                </button>
                </div>
            </div>
                    <div className="card card-body border-0 shadow table-wrapper table-responsive">
                            <table className="table table-hover">
                              <thead>
                                 <tr>
                                     <th className="border-gray-200">Symbol</th>
                                     <th className="border-gray-200">Base Prec</th>
                                     <th className="border-gray-200">Quote Prec</th>
                                     <th className="border-gray-200">Min Notional</th>
                                     <th className="border-gray-200">Min Lot Size</th>
                                     <th className="border-gray-200">Actions</th>
                                 </tr>
                              </thead>
                              <tbody>
                                  {symbols.map(item => <SymbolRow key={item.symbol} data={item} onClick={onViewClick} />)}
                              </tbody>
                            </table>
                           <Pagination count={count} />
                        </div>
             <Footer />
        </main>
        <Toast type={notification.type} text={notification.text} />
     <SymbolModal data={viewSymbol}  />  
    </React.Fragment>
    )
}    

export default Symbols;